exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-1-js": () => import("./../../../src/pages/about-us-1.js" /* webpackChunkName: "component---src-pages-about-us-1-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogs-11-du-an-nguon-mo-ocr-js": () => import("./../../../src/pages/blogs/11-du-an-nguon-mo-ocr.js" /* webpackChunkName: "component---src-pages-blogs-11-du-an-nguon-mo-ocr-js" */),
  "component---src-pages-blogs-huong-dan-su-dung-ekyc-js": () => import("./../../../src/pages/blogs/huong-dan-su-dung-ekyc.js" /* webpackChunkName: "component---src-pages-blogs-huong-dan-su-dung-ekyc-js" */),
  "component---src-pages-blogs-nhan-dien-chu-tv-trong-anh-ngoai-canh-js": () => import("./../../../src/pages/blogs/nhan-dien-chu-tv-trong-anh-ngoai-canh.js" /* webpackChunkName: "component---src-pages-blogs-nhan-dien-chu-tv-trong-anh-ngoai-canh-js" */),
  "component---src-pages-blogs-vietocr-js": () => import("./../../../src/pages/blogs/vietocr.js" /* webpackChunkName: "component---src-pages-blogs-vietocr-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-faq-2-js": () => import("./../../../src/pages/faq-2.js" /* webpackChunkName: "component---src-pages-faq-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-1-js": () => import("./../../../src/pages/services-1.js" /* webpackChunkName: "component---src-pages-services-1-js" */),
  "component---src-pages-services-2-js": () => import("./../../../src/pages/services-2.js" /* webpackChunkName: "component---src-pages-services-2-js" */),
  "component---src-pages-services-3-js": () => import("./../../../src/pages/services-3.js" /* webpackChunkName: "component---src-pages-services-3-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

